<template>
  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
    <loader v-if="!isContentLoaded" :is-visible="!isContentLoaded"></loader>
    <div v-if="isContentLoaded">
      <div class="iq-card-header d-flex justify-content-between">
        <apex-chart-header :disable-range-selection="true" :header="header"></apex-chart-header>
      </div>
      <div class="card-body iq-card-body p-1" style="position: relative;">
        <apexchart type="bubble" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
    </div>
  </iq-card>
</template>

<script>
  import Loader from "../../components/shared/Loader";
  import ApexChartHeader from "../../components/apexCharts/ApexChartHeader";

  import ChartService from "../../services/chart";
  import "../../plugins/apexchart";

  export default {
    components: {
      Loader,
      ApexChartHeader
    },
    props: {
      dataUrl: String,
      dataObject: Object
    },
    data() {
      return {
        date: { startDate: '', endDate: '' },
        isContentLoaded: false,
        header: '',
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'bubble',
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
              }
            },
            defaultLocale: 'tr',
            locales: [{
              name: 'tr',
              options: {
                toolbar: {
                  "exportToSVG": this.$t('DownloadSvg'),
                  "exportToPNG": this.$t('DownloadPng'),
                  "menu": this.$t('Menu'),
                  "selection": this.$t('Selection'),
                  "selectionZoom": this.$t('SelectionZoom'),
                  "zoomIn": this.$t('ZoomIn'),
                  "zoomOut": this.$t('zoomOut'),
                  "pan": this.$t('Panning'),
                  "reset": this.$t('ResetZoom')
                }
              }
            }]
          },
          dataLabels: {
            enabled: false
          },
          colors: ['#30acbc', '#fec047', '#33e9ab'],
          fill: {
            opacity: 0.8
          },
          xaxis: {
            type: 'datetime',
            labels: {
              format: 'dd/MM',
            },
          },
          tooltip: {
            x: {
              format: 'dd.MM.yyyy'
            },
          },
        },
      }
    },
    methods: {
      getData() {
        ChartService.getChartData(this.url)
          .then(response => {
            console.dir(response);
            if (response) {
              this.setData(response);
            }
          });
      },
      setData(filledChartData) {
        let $this = this;
        console.dir(filledChartData);

        $this.series = filledChartData.series.slice();

        for (var i = 0; i < $this.series.length; i++) {
          $this.series[i].name = $this.$t($this.series[i].name);
        }
        $this.header = $this.$t(filledChartData.chartHeader);

        $this.isContentLoaded = true;
      }
    },
    watch: {
      date: function () {
        this.getData()
      },
      chartData: function () {
        this.setData(this.chartData);
      },
      url: function () {
        this.getData();
      }
    },
    computed: {
      chartUniqueId: function () {
        return "apexBubbleChart"
      },
      chartData: function () {
        return this.dataObject;
      },
      url: function () {
        return this.dataUrl;
      }
    },
    mounted: function () {
      //this.date.startDate = this.$moment().subtract(15, 'days').format("YYYY-MM-DD");
      //this.date.endDate = this.$moment().format("YYYY-MM-DD");
    }
  }
</script>


<style lang="scss" scoped>
    ::v-deep .apexcharts-menu-item.exportCSV {
        display: none;
    }

    ::v-deep .apexcharts-menu.apexcharts-menu-open {
        min-width: 82px !important;
    }
</style>
